import React from 'react';
import { Flex, Box, Link } from 'rebass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const GymiousBanner = () => {
  const [isClose, setIsClose] = React.useState(false);

  return  (
    !isClose ?
      <Flex py={1} px={2} bg='blue' color="white" textAlign="center" fontWeight="semibold" fontSize={[0, 1]} alignItems="center" justifyContent="center">
        <div>
          Treinar nunca foi tão fácil! Descobre mais de 100 espaços fitness com o Gymious.
          <Link color="white" fontWeight="semibold" href="https://gymious.pt/?ref=gymlovers" mx={1}>Ver mais.</Link>
        </div>
        <CloseIcon mx={[2,3]} onClick={() => setIsClose(true)}>
          <FontAwesomeIcon
              color={"white"}
              icon={['fa', 'times']}
          />
        </CloseIcon>
      </Flex> : null
  );
}

const CloseIcon = styled(Box)`
  cursor: pointer;
  font-size: 14px;
`;

export default GymiousBanner;

import React from 'react';
import styled, { css } from 'styled-components';
import { Box } from 'rebass';
import { AnimationIn } from '../ui';
import { phone, laptop } from '../layouts/media';
import { GatsbyImage } from "gatsby-plugin-image";

export const HeroSideImage = ({ children, image, color, ...props }) => {
  return (
    <Box {...props}>
      <Grid>
        <AnimationIn seconds={'1.5s'}>
          <BackgroundImage image={image.childImageSharp.gatsbyImageData} color={color} />
        </AnimationIn>
        <Center mt={[2, 3, 4]} mx={[3, 4]}>
          {children}
        </Center>
      </Grid>
    </Box>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.3fr;

  ${laptop(
    css`
      grid-template-columns: 1fr 1.5fr;
    `
  )};

  ${phone(css`
    grid-template-columns: 1fr;
  `)}; };
`;

const BackgroundImage = styled(GatsbyImage)`
  width: 100%;
  height: 100vh;
  object-position: 20% center !important;

  & > picture {
    & > img {
      object-position: 20% center !important;

      ${laptop(css`
        object-position: 28% 0% !important;
        border-radius: 24px 0 0 24px !important;;
      `)};
    }
  }

  ${laptop(css`
    height: 600px;
  `)};

  ${phone(css`
    height: 258px;
    animation: none;
  `)};
`;

const Center = styled(Box)`
  max-width: 740px;

  ${laptop(css`
    max-width: none;
  `)};

  ${phone(css`
    max-width: none;
  `)};
`;

import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { Container } from '../ui';
import { Link as RebassLink, Box } from 'rebass';
import { phone } from '../layouts/media';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

const Footer = () => (
  <FooterStyles>
    <Container width={1} px={[3, 4, 3]} my={[4, 0]}>
      <FooterContainer>
        <NavLinks>
          <NavLink fontSize={[1, 2]} py={1} as={Link} to="/entrevistas">
            Entrevistas
          </NavLink>
          <NavLink fontSize={[1, 2]} py={1} as={Link} to="/musica">
            Música
          </NavLink>
          <NavLink fontSize={[1, 2]} py={1} as={Link} to="/sobre">
            Sobre nós
          </NavLink>
          <NavLink
            href="mailto:info@gymlovers.pt?Subject=Hello%20from%20Gymlovers"
            fontSize={[1, 2]}
            py={1}
          >
            Contacta-nos
          </NavLink>
        </NavLinks>
        <FooterSocial my={[3, 0]}>
          <OutboundLink
            href="https://twitter.com/gymloverspt"
            target="_blank"
            rel="noreferrer"
            aria-label="Twitter"
          >
            <Social>
              <FontAwesomeIcon icon={['fab', 'twitter']} />
            </Social>
          </OutboundLink>
          <OutboundLink
            href="https://fb.me/gymloverspt"
            target="_blank"
            rel="noreferrer"
            aria-label="Facebook"
          >
            <Social>
              <FontAwesomeIcon icon={['fab', 'facebook-f']} />
            </Social>
          </OutboundLink>
          <OutboundLink
            href="https://www.instagram.com/gymloverspt/"
            target="_blank"
            rel="noreferrer"
            aria-label="Instagram"
          >
            <Social>
              <FontAwesomeIcon icon={['fab', 'instagram']} />
            </Social>
          </OutboundLink>
        </FooterSocial>
      </FooterContainer>
    </Container>
  </FooterStyles>
);

export default Footer;

const FooterStyles = styled.div`
  display: flex;
  align-items: center;

  height: 158px;

  background-color: ${props => props.theme.colors.white};

  ${phone(css`
    height: 100%;
  `)};
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${phone(css`
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
  `)};
`;

const NavLinks = styled.nav`
  margin: 0;
  text-align: right;

  ${phone(css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding-right: 0;
    padding-left: 0;

    text-align: center;
  `)};
`;

const NavLink = styled(RebassLink)`
  margin-right: 20px;
  font-family: ${props => props.theme.fonts.family};
  font-weight: 500;

  text-decoration: none;
  color: ${props => props.theme.colors.blackish};

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    border-bottom: 2px solid ${props => props.theme.colors.blue};
  }

  ${phone(css`
    margin: 24px 0;

    &:hover {
      border-bottom: none;
    }

    &:last-child {
      margin-bottom: 40px;
    }
  `)};
`;

const FooterSocial = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: right;

  width: 220px;

  ${phone(css`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 140px;
  `)};
`;

const Social = styled.div`
  font-size: 28px;
  color: ${props => props.theme.colors.blackish};

  &:hover {
    color: ${props => props.theme.colors.blackish70};
  }
`;

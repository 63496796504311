import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Box } from 'rebass';
import { phone } from '../layouts/media';
import { fadeIn, fadeInUp } from 'react-animations';

export const AnimationUp = ({ children, seconds }) => {
  return <AnimationUpStyle seconds={seconds}>{children}</AnimationUpStyle>;
};

export const AnimationIn = ({ children, seconds }) => {
  return <AnimationInStyle seconds={seconds}>{children}</AnimationInStyle>;
};

const fadeUpAnimation = keyframes`${fadeInUp}`;

const AnimationUpStyle = styled(Box)`
  animation: ${props => props.seconds[1]} ${fadeUpAnimation};

  ${phone(css`
    animation: ${props => props.seconds[0]} ${fadeUpAnimation};
  `)};
`;

const AnimationInStyle = styled(Box)`
  animation: ${props => props.seconds} ${keyframes`${fadeIn}`};

  ${phone(css`
    animation: none;
  `)};
`;

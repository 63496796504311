import { css } from 'styled-components';
// const breakpoints = [32, 40, 48, 64, 80]

export const desktop = inner => css`
  @media (max-width: 80em) {
    ${inner};
  }
`;

export const laptop = inner => css`
  @media (max-width: 64em) {
    ${inner};
  }
`;

export const tablet = inner => css`
  @media (max-width: 48em) {
    ${inner};
  }
`;

export const miniTablet = inner => css`
  @media (max-width: 40em) {
    ${inner};
  }
`;

export const mobile = inner => css`
  @media (max-width: 42em) {
    ${inner};
  }
`;

//old
export const phone = inner => css`
  @media (max-width: ${650 / 16}em) {
    ${inner};
  }
`;

export const mediumPhone = inner => css`
  @media (max-width: 400px) {
    ${inner};
  }
`;

export const smallPhone = inner => css`
  @media (max-width: 320px) {
    ${inner};
  }
`;

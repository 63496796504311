import React from 'react';
import styled, { css } from 'styled-components';
import { Button, TextBody2 } from '../ui';
import { Box, Flex } from 'rebass';
import { phone, tablet, laptop } from '../layouts/media';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
let email;

class CustomForm extends React.Component {
  submit = () => {
    const { onValidated } = this.props;

    return (
      email &&
      email.value.indexOf('@') > -1 &&
      onValidated({
        EMAIL: email.value
      })
    );
  };

  onBlur = () => {
    if (email) {
      email.placeholder = 'O teu endereço de email';
    }
  };

  onFocus = () => {
    if (email) {
      email.placeholder = '';
    }
  };

  render() {
    const { status } = this.props;
    return (
      <Box width="100%">
        {status === 'sending' && (
          <StatusMessageBox mb={3}>
            <TextBody2 color="#454a4e" ml={2} px={4} py={2}>
              <FontAwesomeIcon icon={['fa', 'spinner']} spin />
              A processar a inscrição ...{' '}
              <span role="img" alt="running" aria-label="running">
                🏃
              </span>
            </TextBody2>
          </StatusMessageBox>
        )}
        {status === 'error' && (
          <StatusMessageBox mb={3}>
            <TextBody2 color="#454a4e" ml={2} px={4} py={2}>
            ✔️ Este email já está inscrito.
            </TextBody2>
          </StatusMessageBox>
        )}
        {status === 'success' && (
          <StatusMessageBox mb={3}>
            <TextBody2 mr={3} color="#454a4e" px={4} py={2}>
              ✅ Está quase! Clica no link que te foi enviado para o e-mail para
              confirmares a inscrição.
            </TextBody2>
          </StatusMessageBox>
        )}
        <Flex flexDirection={['column', 'row']} justifyContent="center">
          <EmailInput
            aria-label="Email"
            ref={node => (email = node)}
            type="email"
            placeholder="O teu endereço de email"
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          />
          <Btn block="true" onClick={this.submit}>
            Inscreve-me
          </Btn>
        </Flex>
      </Box>
    );
  }
}

export default CustomForm;


const StatusMessageBox = styled(Box)`
  display: flex;
  justify-content: start;
  border-radius: 6px;
  width: fit-content;
  align-items: center;
  border-radius: 6px;
  margin: 12px auto;

  background-color: ${props => props.theme.colors.white};
`;

const Btn = styled(Button)`
  width: 200px;
  height: 80px;
  margin-left: -2px;
  border: 1px solid  ${props => props.theme.colors.blue};
  border-radius:  0px 12px 12px 0px;

  background: ${props => props.theme.colors.blue};

  &:hover {
    box-shadow: none;
    background: ${props => props.theme.colors.blue};
  }

  ${laptop(css`
    width: 200px;
    height: 70px;
  `)};

  ${tablet(css`
    width: 100px;
    height: 62px;
    border-radius:  0px 12px 12px 0px;
  `)};

  ${phone(css`
    border-radius: 8px;
    margin-left: 0;
    width: 100%;
    height: 52px;
  `)};
`;

const EmailInput = styled.input`
  width: 560px;
  height: 80px;
  padding: 8px 16px;
  border: 1px solid;
  border-color: ${props => props.theme.colors.gray[0]};
  border-radius: 12px 0px 0px 12px;

  background: white;

  font-family: ${props => props.theme.fonts.family};
  font-size: 18px;

  box-shadow: none;
  outline: none;

  color: ${props => props.theme.colors.gray[2]};

  &:focus {
    box-shadow: none;
  }

  ${laptop(css`
    width: 460px;
    height: 70px;
  `)};

  ${tablet(css`
    width: 380px;
    height: 62px;
    margin-bottom: 0px;
    border-radius: 12px 0px 0px 12px;
  `)};

  ${phone(css`
    border-radius: 8px;
    text-align: center;
    width: 100%;
    height: 52px;
    margin-bottom: 12px;
  `)};
`;

import * as React from "react";

function Logo({width=122,height=32,color="#2D3135",...props}) {
  return (
    <svg
      width="122"
      height="32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 122 32"
      {...props}
    >
      <path d="M9.14481 7.92031C10.1379 7.92031 11.0565 8.061 11.9007 8.34238C12.7448 8.62376 13.4896 8.98789 14.1352 9.43479C14.7807 9.88169 15.2855 10.3617 15.6496 10.8748L13.9117 12.6872C13.2496 12.0582 12.5214 11.5451 11.7269 11.1479C10.9324 10.7341 10.0717 10.5272 9.14481 10.5272C7.96964 10.5272 6.90205 10.8003 5.94205 11.3465C4.99861 11.8927 4.25378 12.6376 3.70757 13.581C3.16136 14.5079 2.88826 15.5672 2.88826 16.7589C2.88826 17.9341 3.17792 19.0017 3.75723 19.9617C4.33654 20.9217 5.10619 21.6831 6.06619 22.2458C7.04274 22.8086 8.10205 23.09 9.24412 23.09C10.0717 23.09 10.8496 22.9327 11.5779 22.6182C12.3062 22.2872 12.8938 21.8403 13.3407 21.2775C13.8041 20.7148 14.0358 20.0693 14.0358 19.341C14.0358 19.2582 14.0358 19.2086 14.0358 19.192H9.09516V16.5355H16.7917C16.8248 16.7838 16.8496 17.032 16.8662 17.2803C16.8993 17.5286 16.9158 17.7769 16.9158 18.0251C16.9158 18.9355 16.7752 19.8293 16.4938 20.7065C16.2289 21.5838 15.8069 22.3451 15.2276 22.9907C14.5655 23.8182 13.7048 24.4803 12.6455 24.9769C11.6027 25.4569 10.4441 25.6969 9.16964 25.6969C7.91171 25.6969 6.73654 25.4734 5.64412 25.0265C4.55171 24.5631 3.59171 23.9258 2.76412 23.1148C1.93654 22.3038 1.29102 21.3603 0.827571 20.2844C0.380675 19.192 0.157227 18.0169 0.157227 16.7589C0.157227 15.501 0.380675 14.3424 0.827571 13.2831C1.29102 12.2072 1.92826 11.272 2.7393 10.4775C3.56688 9.66652 4.52688 9.03755 5.61929 8.59065C6.71171 8.14376 7.88688 7.92031 9.14481 7.92031Z" fill={color}/>
      <path d="M20.9121 31.1589L23.0472 26.2679L23.1217 26.0941L17.0638 12.4141H20.0431L24.1148 21.9975C24.1645 22.0969 24.2141 22.221 24.2638 22.37C24.3134 22.5189 24.3714 22.6844 24.4376 22.8665C24.5038 22.6844 24.5617 22.5107 24.6114 22.3451C24.6776 22.1631 24.7438 21.9893 24.81 21.8238L28.2114 12.4141H31.2403L25.8776 25.4486L23.6679 31.1589H20.9121Z" fill={color}/>
      <path d="M39.2651 12.1907C40.341 12.1907 41.2017 12.4389 41.8472 12.9355C42.4927 13.4155 42.9562 14.0775 43.2375 14.9217C43.4693 14.5079 43.8086 14.0941 44.2555 13.6803C44.7024 13.25 45.2237 12.8941 45.8196 12.6127C46.4155 12.3313 47.0527 12.1907 47.7313 12.1907C48.8569 12.1907 49.7341 12.4389 50.3631 12.9355C50.992 13.4155 51.4306 14.0693 51.6789 14.8969C51.9437 15.7079 52.0762 16.6182 52.0762 17.6279V25.4486H49.4941V17.7769C49.4941 16.8169 49.312 16.0389 48.9479 15.4431C48.5838 14.8472 47.8969 14.5493 46.8872 14.5493C45.9272 14.5493 45.1327 14.8555 44.5038 15.4679C43.8913 16.0638 43.5851 16.792 43.5851 17.6527V25.4486H41.0031V17.7272C41.0031 16.8003 40.8044 16.0389 40.4072 15.4431C40.01 14.8472 39.3562 14.5493 38.4458 14.5493C37.8169 14.5493 37.2541 14.69 36.7575 14.9713C36.261 15.2527 35.872 15.6251 35.5906 16.0886C35.3093 16.5355 35.1686 17.0403 35.1686 17.6031V25.4486H32.5865V12.4141H35.1686V14.4996C35.5658 13.9038 36.1203 13.3741 36.832 12.9107C37.5603 12.4307 38.3713 12.1907 39.2651 12.1907Z" fill={color}/>
      <path d="M54.4654 25.4486V7.07617H57.0226V25.4486H54.4654Z" fill={color}/>
      <path d="M72.4008 18.9189C72.4008 20.2431 72.1029 21.4182 71.507 22.4444C70.9277 23.4541 70.1333 24.2486 69.1236 24.8279C68.1305 25.4072 67.0132 25.6969 65.7719 25.6969C64.5305 25.6969 63.405 25.4072 62.3953 24.8279C61.3857 24.2486 60.5829 23.4541 59.987 22.4444C59.4077 21.4182 59.1181 20.2431 59.1181 18.9189C59.1181 17.5948 59.4077 16.4279 59.987 15.4182C60.5829 14.392 61.3857 13.5893 62.3953 13.01C63.405 12.4307 64.5305 12.141 65.7719 12.141C67.0132 12.141 68.1305 12.4307 69.1236 13.01C70.1333 13.5893 70.9277 14.392 71.507 15.4182C72.1029 16.4279 72.4008 17.5948 72.4008 18.9189ZM69.7939 18.9189C69.8105 18.0417 69.6367 17.272 69.2726 16.61C68.925 15.9313 68.445 15.41 67.8326 15.0458C67.2201 14.6651 66.5332 14.4748 65.7719 14.4748C65.0105 14.4748 64.3153 14.6651 63.6864 15.0458C63.0739 15.41 62.5857 15.9313 62.2215 16.61C61.8739 17.272 61.7084 18.0417 61.725 18.9189C61.7084 19.7796 61.8739 20.5493 62.2215 21.2279C62.5857 21.89 63.0739 22.4113 63.6864 22.792C64.3153 23.1727 65.0105 23.3631 65.7719 23.3631C66.5332 23.3631 67.2201 23.1727 67.8326 22.792C68.445 22.4113 68.925 21.89 69.2726 21.2279C69.6367 20.5493 69.8105 19.7796 69.7939 18.9189Z" fill={color}/>
      <path d="M75.8209 12.4141L79.6195 21.7244L83.5671 12.4141H86.1988L80.414 25.4486H78.5768L73.065 12.4141H75.8209Z" fill={color}/>
      <path d="M98.8549 23.7355H98.8301C98.3005 24.2486 97.5722 24.7038 96.6453 25.101C95.7184 25.4982 94.7501 25.6969 93.7405 25.6969C92.3832 25.6969 91.1832 25.4155 90.1405 24.8527C89.1143 24.2734 88.3115 23.4872 87.7322 22.4941C87.1529 21.4844 86.8632 20.3424 86.8632 19.0679C86.8632 17.6113 87.1694 16.37 87.7818 15.3438C88.3943 14.301 89.2053 13.5065 90.2149 12.9603C91.2246 12.3976 92.3253 12.1162 93.517 12.1162C94.6094 12.1162 95.6025 12.3893 96.4963 12.9355C97.3901 13.4651 98.1018 14.2017 98.6315 15.1451C99.1612 16.0886 99.426 17.1893 99.426 18.4472L99.4012 19.5396H89.4205C89.5694 20.7148 90.0494 21.65 90.8605 22.3451C91.6881 23.0238 92.7308 23.3631 93.9887 23.3631C94.8991 23.3631 95.6356 23.1975 96.1984 22.8665C96.7612 22.5189 97.2246 22.1879 97.5887 21.8734L98.8549 23.7355ZM93.517 14.4748C92.5405 14.4748 91.6881 14.7065 90.9598 15.17C90.2315 15.6169 89.7515 16.3948 89.5198 17.5038H96.8191V17.33C96.7694 16.7672 96.5791 16.2707 96.248 15.8403C95.9336 15.41 95.5363 15.0789 95.0563 14.8472C94.5763 14.5989 94.0632 14.4748 93.517 14.4748Z" fill={color}/>
      <path d="M108.987 15.17C108.822 15.0872 108.615 15.021 108.366 14.9713C108.135 14.9051 107.886 14.872 107.622 14.872C107.092 14.872 106.571 15.0127 106.058 15.2941C105.544 15.5589 105.114 15.9479 104.767 16.461C104.435 16.9575 104.27 17.5534 104.27 18.2486V25.4486H101.688V18.9313V12.4141H104.27V14.872C104.634 14.0775 105.18 13.4238 105.909 12.9107C106.653 12.3976 107.431 12.141 108.242 12.141C108.855 12.141 109.326 12.2238 109.658 12.3893L108.987 15.17Z" fill={color}/>
      <path d="M118.707 15.8403C118.243 15.3769 117.714 14.9962 117.118 14.6982C116.539 14.4003 116.001 14.2513 115.504 14.2513C115.157 14.2513 114.809 14.2927 114.461 14.3755C114.13 14.4582 113.849 14.6155 113.617 14.8472C113.385 15.0624 113.27 15.3851 113.27 15.8155C113.286 16.4113 113.551 16.8334 114.064 17.0817C114.594 17.33 115.248 17.5534 116.025 17.752C116.704 17.9341 117.366 18.1658 118.012 18.4472C118.657 18.712 119.187 19.1093 119.601 19.6389C120.031 20.152 120.246 20.8803 120.246 21.8238C120.246 22.6348 120.014 23.33 119.551 23.9093C119.104 24.4886 118.517 24.9355 117.788 25.25C117.077 25.5479 116.323 25.6969 115.529 25.6969C114.585 25.6969 113.65 25.5313 112.723 25.2003C111.813 24.8527 111.035 24.2651 110.39 23.4375L112.128 21.9479C112.574 22.461 113.079 22.8665 113.642 23.1644C114.221 23.4458 114.925 23.5865 115.752 23.5865C116.067 23.5865 116.381 23.5369 116.696 23.4375C117.01 23.3217 117.275 23.1479 117.49 22.9162C117.722 22.6679 117.838 22.3369 117.838 21.9231C117.838 21.5258 117.714 21.2196 117.465 21.0044C117.234 20.7727 116.919 20.5907 116.522 20.4582C116.125 20.3093 115.686 20.1769 115.206 20.061C114.494 19.8624 113.791 19.6224 113.096 19.341C112.417 19.0431 111.846 18.6293 111.383 18.0996C110.936 17.5534 110.712 16.8169 110.712 15.89C110.712 15.112 110.928 14.45 111.358 13.9038C111.805 13.341 112.384 12.9107 113.096 12.6127C113.808 12.2982 114.569 12.141 115.38 12.141C116.158 12.141 117.002 12.3065 117.912 12.6375C118.823 12.9686 119.576 13.4982 120.172 14.2265L118.707 15.8403Z" fill={color}/>
  </svg>
  );
}

export default Logo;
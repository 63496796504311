import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { Link as RebassLink, Box } from 'rebass';
import Logo from './Logo';
import { Container } from '../../ui';
import { phone } from '../../layouts/media';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const socialMedias = [
  {
    link: 'https://twitter.com/gymloverspt',
    faIcon: 'twitter'
  },
  {
    link: 'https://fb.me/gymloverspt',
    faIcon: 'facebook-f'
  },
  {
    link: 'https://www.instagram.com/gymloverspt/',
    faIcon: 'instagram'
  }
];

const correctColor = (open, inverted) => (open ? 'white' : inverted && 'white');

const MobileNavbar = ({ open, toggleOpen, closeNav, inverted }) => (
  <Nav open={open} inverted={inverted}>
    <NavOpenWrapper open={open}>
      <NavTop>
        <LogoLink as={Link} to="/" onClick={closeNav}>
          <Logo color={correctColor(open, inverted)} />
        </LogoLink>
        <Box fontSize={[4]} onClick={toggleOpen}>
          <FontAwesomeIcon
            color={correctColor(open, inverted)}
            icon={open ? ['fa', 'times'] : ['fa', 'bars']}
          />
        </Box>
      </NavTop>
      <Container w={1}>
        <NavLinks open={open}>
          <NavLink onClick={closeNav} as={Link} to="/entrevistas">
            Entrevistas
          </NavLink>
          <NavLink onClick={closeNav} as={Link} to="/musica">
            Música
          </NavLink>
          <NavLink onClick={closeNav} as={Link} to="/sobre">
            Sobre
          </NavLink>
          <NavLink
            onClick={closeNav}
            href="#subscribe"
            id="subscribeLinkMobile"
          >
            Newsletter
          </NavLink>
        </NavLinks>
        <SocialMediasRow open={open} pt={4}>
          {socialMedias.map(socialMedia => (
            <RebassLink
              rel="noreferrer"
              href={socialMedia.link}
              target="_blank"
              key={socialMedia.faIcon}
            >
              <Social>
                <FontAwesomeIcon icon={['fab', socialMedia.faIcon]} />
              </Social>
            </RebassLink>
          ))}
        </SocialMediasRow>
      </Container>
      <div />
    </NavOpenWrapper>
  </Nav>
);

export default MobileNavbar;

const Nav = styled.nav`
  display: none;
  position: ${props => (props.open ? 'fixed' : 'relative')};
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  z-index: 10;
  width: 100%
  overflow: hidden;

  ${phone(css`
    display: block;
    height: ${props => (props.open ? '100vh' : '65px')};
    background-color: ${props =>
      props.open
        ? props.theme.colors.blackish
        : props.inverted
        ? 'transparent'
        : props.theme.colors.white};
  `)};
`;

const NavOpenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${props => (props.open ? '100vh' : '65px')};
`;

const NavTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
  text-align: center;
`;

const LogoLink = styled(RebassLink)`
  display: flex;
  height: 37px;
`;

const NavLinks = styled.ul`
  display: ${props => (props.open ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  height: 300px;
  margin: 0;
  padding: 0;
`;

const NavLink = styled(RebassLink)`
  font-family: ${props => props.theme.fonts.family};
  font-weight: 500;
  font-size: 24px;

  margin-bottom: 48px;

  text-decoration: none;
  color: ${props => props.theme.colors.whitish};
`;

const SocialMediasRow = styled(Box)`
  display: ${props => (props.open ? 'flex' : 'none')};
  justify-content: space-around;
  align-items: center;

  width: 220px;
  margin: 0 auto;
`;

const Social = styled.div`
  font-size: 28px;
  color: ${props => props.theme.colors.whitish};
`;

import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Linkstyle = styled(Link)`
  color: inherit;
  text-decoration: none;
`;

export const LinkNoStyles = ({ children, ...props }) => {
  return <Linkstyle {...props}>{children}</Linkstyle>;
};

import React from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

const url =
  'https://gymlovers.us19.list-manage.com/subscribe/post?u=5ef72a06d198d7035dec4fd70&id=8792811034';

const MailchimpForm = ({ Form }) => (
  <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (
      <Form
        status={status}
        message={message}
        onValidated={formData => subscribe(formData)}
      />
    )}
  />
);

export default MailchimpForm;

import React from 'react';
import styled, { css } from 'styled-components';
import { Box, Flex } from 'rebass';
import { H3, Container } from '../ui';
import { phone } from '../layouts/media';
import CustomForm from './CostumForm';

import MailchimpForm from './MailchimpForm';

const
  Subscribe = () => {
  return (
    <Cta py={[5, 6]} id="subscribe">
      <Container>
        <H3 color="blackish" fontSize={[3, 5, 6]} fontWeight={'500'} textAlign={["center"]}>
          📬️ Recebe novas entrevistas no teu e-mail 
        </H3>
        <Content my={[4, 5]}>
          <MailchimpForm Form={CustomForm} />
        </Content>
      </Container>
    </Cta>
  );
};

const Content = styled(Flex)`
  ${phone(css`
    flex-direction: column;
  `)};
`;

const Cta = styled(Box)`
  background: ${props => props.theme.colors.lightBlue};
`;

export default Subscribe;

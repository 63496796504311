import React, { useState } from 'react';
import Navbar from './Navbar';
import MobileNavbar from './MobileNavbar';

const Nav = ({ inverted }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Navbar inverted={inverted} />
      <MobileNavbar
        inverted={inverted}
        toggleOpen={() => setOpen(!open)}
        closeNav={() => setOpen(false)}
        open={open}
      />
    </>
  );
};

export default Nav;

import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Box, Flex } from 'rebass';
import {
  Button,
  TextBody1,
  TextBody2,
  MiniText,
  H5,
  H4,
  LinkNoStyles
} from './index';
import { phone } from '../layouts/media';
import theme from '../layouts/theme';
import { GatsbyImage } from "gatsby-plugin-image";
import { fadeInUp } from 'react-animations';
import { getFormatedDate } from '../components/Utilities';

export const InterviewCard = ({ post, date, color, ...props }) => {
  return (
    <Box key={post.id} {...props}>
      <LinkNoStyles to={post.fields.slug}>
        <BackgroundImage
          image={post.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
          BackgroundColor={post.frontmatter.color}
        />
      </LinkNoStyles>
      <Flex alignItems={['baseline']} flexDirection={['column']}>
        <Box mt={1} mb={[-1, -2]}>
          <MiniText>{getFormatedDate(post.frontmatter.date)}</MiniText>
        </Box>
        <LinkNoStyles to={post.fields.slug}>
          <H5 color={color} fontSize={[3, 4]}>
            {post.frontmatter.title}
          </H5>
        </LinkNoStyles>
      </Flex>
      <TextBody2 color={color} fontFamily={theme.fonts.family}>
        {post.excerpt}
      </TextBody2>
    </Box>
  );
};

const BackgroundImage = styled(GatsbyImage)`
  height: 0px;

  padding-bottom: 116%;

  & > picture {
    & > img {
      border-radius: 24px;
    }
  }
`;

export const MainCard = ({ post, image, ...props }) => {
  return (
    <CardBox {...props}>
      <Image image={image.childImageSharp.gatsbyImageData} backgroundColor={post.frontmatter.color} />
      <CardContent py={3} px={2}>
        <LinkNoStyles to={post.fields.slug}>
          <TitleContainer px={3}>
            <H4 fontWeight={500} my={0} py={2}>
              {post.frontmatter.title}
            </H4>
          </TitleContainer>
          <TextContainer px={3} pb={3}>
            <TextBody1 dangerouslySetInnerHTML={{ __html: post.frontmatter.description }} />
            <Button primary="true" block="true" mt={3}>
              Ver entrevista
            </Button>
          </TextContainer>
        </LinkNoStyles>
      </CardContent>
    </CardBox>
  );
};

const CardBox = styled(Box)`
  display: none;

  ${phone(css`
    display: block;
  `)}; };
`;

const Image = styled(GatsbyImage)`
  z-index: -1;

  & > div {
    padding-top: 24% !important;
    padding-bottom: 72% !important;
  }

  & > picture {
    & > img {
      object-position: 62% 12% !important;
    }
  }
`;

const TitleContainer = styled(Box)``;

const TextContainer = styled(Box)``;

const fadeUpAnimation = keyframes`${fadeInUp}`;

const CardContent = styled(Box)`
  width: 100;

  margin-top: -54px;

  background-color: ${props => props.theme.colors.lightBlue};

  color: ${props => props.theme.colors.blackish};

  animation: 1.5s ${fadeUpAnimation};
`;

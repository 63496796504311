const colorPalette = {
  blackish: "#2D3135",
  blackish70: "rgba(45, 49, 53,0.70)",
  whitish: "#F7F7F7",
  white: "white",
  blue: "#3470E8",
  lightBlue: "#F1F5FC",
  black: "black",
  gray: ["#d9d9d9", "#8e8e93", "#454A4E", "#2D3135"],
  green: "#3b975b",
  lightGreen: "#D5E7D2",
  red: "#ea5d67",
  yellow: "#e4b700",
  beige: "#f2ece3",
};

const colors = {
  blackish: colorPalette.blackish,
  whitish: colorPalette.whitish,
  blackish70: colorPalette.blackish70,
  gray: colorPalette.gray,
  white: colorPalette.white,
  black: colorPalette.black,
  green: colorPalette.green,
  lightGreen: colorPalette.lightGreen,
  red: colorPalette.red,
  yellow: colorPalette.yellow,
  blue: colorPalette.blue,
  lightBlue: colorPalette.lightBlue,
  beige: colorPalette.beige,
};

const fonts = {
  family: "'Inter', sans-serif",
};

const shadows = [
  "0 5px 30px rgba(156,160,171,0.25)",
  "0 5px 12px rgba(156,160,171,0.22)",
];

const borders = [0, "1px solid", `2px solid ${colorPalette.gray[1]}`];

const fontSizes = [13, 15, 17, 21, 24, 32, 48, 56, 68];

const breakpoints = [32, 40, 48, 64, 80].map((n) => n + "em");

const theme = {
  fonts,
  shadows,
  colors,
  borders,
  fontSizes,
  breakpoints,
};

export default theme;

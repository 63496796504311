import React from 'react';
import sys from 'system-components';
import { Box } from 'rebass';
import styled, { css } from 'styled-components';
import { laptop, phone, mediumPhone } from '../layouts/media';

export const ContainerBox = sys({
  is: Box,
  px: [4, 4, 5, 5, 5],
  maxWidth: '1300px',
  mx: 'auto'
});

export const Container = ({ children, ...props }) => {
  return <ContainerBox {...props}>{children}</ContainerBox>;
};

export const TextContainerBox = sys({
  is: Box,
  px: [3, 4, 4, 4, 0],
  maxWidth: '720px',
  mx: 'auto'
});

export const TextContainer = ({ children, ...props }) => {
  return <TextContainerBox {...props}>{children}</TextContainerBox>;
};

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 70px;

  ${laptop(css`
    grid-template-columns: 1fr 1fr;
    grid-gap: 48px;
  `)};

  ${phone(css`
    margin-top: 42px;
    grid-template-columns: 1fr;
    grid-row-gap: 48px;
  `)};

  ${mediumPhone(css`
    grid-template-columns: 1fr;
    grid-row-gap: 48px;
  `)};
`;

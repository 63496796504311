import React from 'react';
import sys from 'system-components';
import { Button as RebassButton } from 'rebass';
import theme from '../layouts/theme';

export const Button = ({ children, ...props }) => {
  return <ButtonBase {...props}>{children}</ButtonBase>;
};

export const ButtonBase = sys(
  {
    is: RebassButton,
    minWidth: '254px',
    py: '18px',
    px: '46px',
    bg: theme.colors.blue,
    border: 0,
    color: theme.colors.whitish,
    textAlign: 'center',
    fontFamily: theme.fonts.family,
    fontSize: [2, 2, 2, 2, 3, 3],
    fontWeight: 500,
    borderRadius: '12px',
  },
  props => ({
    cursor: 'pointer',
    background: props.secondary ? theme.colors.blackish : theme.colors.blue,
    color: theme.colors.white,
    width: props.block ? '100%' : null,
    '&:focus': {
      boxShadow: 'none'
    },
    '&:hover': {
      boxShadow: theme.shadows[1]
    },
    '&:active': {
      outline: 'none'
    }
  })
);

export default Button;

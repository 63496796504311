import React, { Component } from 'react';
import styled from 'styled-components';
import { TextBody2 } from '../ui';
import { Box } from 'rebass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
let email;

class NewsletterForm extends Component {
  submit = () => {
    const { onValidated } = this.props;

    return (
      email &&
      email.value.indexOf('@') > -1 &&
      onValidated({
        EMAIL: email.value
      })
    );
  };

  onBlur = () => {
    if (email) {
      email.placeholder = 'O teu endereço de email';
    }
  };

  onFocus = () => {
    if (email) {
      email.placeholder = '';
    }
  };

  render() {
    const { status } = this.props;

    return (
      <>
        <StatusMessageBox px={1} mb={2}>
          {status === 'sending' && (
            <>
              <IconBox p={1} mr={2} error="true">
                <FontAwesomeIcon icon='spinner' spin />
              </IconBox>
              <TextBody2 color="#454a4e" ml={2}>
                A processar a inscrição ...{' '}
                <span role="img" alt="running emoji" aria-label="running emoji">
                  🏃
                </span>
              </TextBody2>
            </>
          )}
          <TextBody2 color="#454a4e" ml={2}>
            {status === 'error' && '⚠️ Este email já está inscrito.'}
            {status === 'success' &&
              '✔️ Clica no link que te foi enviado para o email.'}
          </TextBody2>
        </StatusMessageBox>

        <Input
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          aria-label="Email"
          type="email"
          ref={node => (email = node)}
          placeholder="O teu endereço de email"
        />
        <Btn type="submit" onClick={this.submit}>
          Inscreve-me
        </Btn>
      </>
    );
  }
}

export default NewsletterForm;

const IconBox = styled(Box)`
  font-size: 12px;
  color: ${props => props.theme.colors.gray[2]};
`;

const StatusMessageBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;

  width: 100%;

  background-color: ${props => props.theme.colors.whitish};
`;

const Input = styled.input`
  width: 100% !important;
  background-color: #f0f0f0;
  margin: 12px auto;
  margin-top: 0;
  padding: 8px 24px;
  font-size: 1.0625rem;
  text-decoration: none;
  text-align: center;
  line-height: 1;
  height: 54px;
  -webkit-appearance: none;
  outline: none;
  border: none;
  border-radius: 12px;
`;

const Btn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 54px;

  font-size: 1.0625rem;
  text-align: center;
  font-weight: 500;
  color: white;
  margin: 6px auto;
  background: ${({ theme }) => theme.colors.blackish};
  border-radius: 12px;

  cursor: pointer;
`;

import React from 'react';
import styled, { css } from 'styled-components';
import { Box } from 'rebass';
import { phone, laptop } from '../layouts/media';

export const HideOnPhone = ({ children, ...props }) => {
  return <HidePhone {...props}>{children}</HidePhone>;
};

export const HideOnDesktop = ({ children, ...props }) => {
  return <HideDesktop {...props}>{children}</HideDesktop>;
};

export const HideOnTabletDesktop = ({ children, ...props }) => {
  return <HideTabletDesktop {...props}>{children}</HideTabletDesktop>;
};

export const HideOnPhoneDesktop = ({ children, ...props }) => {
  return <HidePhoneDesktop {...props}>{children}</HidePhoneDesktop>;
};

export const HideOnTablet = ({ children, ...props }) => {
  return <HideTablet {...props}>{children}</HideTablet>;
};

const HidePhone = styled(Box)`
  ${phone(css`
    display: none;
  `)};
`;

const HideDesktop = styled(Box)`
  display: none;
  ${laptop(css`
    display: block;
  `)};
`;

const HideTabletDesktop = styled(Box)`
  display: none;

  ${phone(css`
    display: block;
  `)};
`;

const HideTablet = styled(Box)`
  display: block;

  ${laptop(css`
    display: none;
  `)};

  ${phone(css`
    display: block;
  `)};
`;

const HidePhoneDesktop = styled(Box)`
  display: none;

  ${laptop(css`
    display: block;
  `)};

  ${phone(css`
    display: none;
  `)};
`;

export const Underline = styled(Box)`
  width: 48px;
  height: 2px;

  background: ${props => props.theme.colors.blue};
`;

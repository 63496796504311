export const logDevsMessage = theme =>
  console.log(
    "%c Welcome to GymLover's Website \n" +
      '%c Thank you to all those behind the incredible frameworks, plugins and services that are used to build this website \n' +
      '%c Please let us know if there are any bugs to fix or improvements to make ->' +
      '%c diogo@gymlovers.pt',
    `background: ${theme.colors.blackish}; color: ${theme.colors.white}`,
    `background: ${theme.colors.white}; color: ${theme.colors.blackish}`,
    `background: ${theme.colors.white}; color: ${theme.colors.blackish}`,
    `background: ${theme.colors.white}; color: ${
      theme.colors.blackish
    }; font-weight: bold`
  );

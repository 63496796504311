import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Box } from 'rebass';
import { GatsbyImage } from "gatsby-plugin-image";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { LinkNoStyles, H5 } from '../ui';
import { phone } from '../layouts/media';
import NewsletterForm from './NewsletterForm';
import MailchimpForm from './MailchimpForm';

const TIME_ON_PAGE = 42000;
const NUMBER_OF_DAYS = 3;

const NewsletterPopUp = ({ interview }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const showDate = localStorage.getItem('NL-popup');
    let timeout = null;

    if (!showDate || new Date().getTime() > showDate) {
      timeout = setTimeout(() => setShow(true), TIME_ON_PAGE);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  });

  const onClose = () => {
    var date = new Date();
    date.setDate(date.getDate() + NUMBER_OF_DAYS);
    localStorage.setItem('NL-popup', date.getTime());
    setShow(false);
  };

  return (
    <NewsletterPopUpStyled show={show} onClick={onClose}>
      <PopUpStyled onClick={e => e.stopPropagation()}>
        <Close icon={['fa', 'times']} onClick={onClose} />
        <LinkNoStyles to={interview.node.fields.slug}>
          <Image
            image={
              interview.node.frontmatter.featuredImage.childImageSharp.gatsbyImageData
            }
          />
        </LinkNoStyles>
        <Content px={[3, 4]} py={[4, 4]} fontSize={[2, 3]}>
          <Title fontSize={[3, 4]}>
            {`Recebe em primeiro lugar entrevistas como esta com 
            ${interview.node.frontmatter.title}`}
            .
          </Title>
          <TitleMobile fontSize={3}>
            Recebe em primeiro lugar as novas entrevistas e novidades.
          </TitleMobile>
          <MailchimpForm Form={NewsletterForm} />
        </Content>
      </PopUpStyled>
    </NewsletterPopUpStyled>
  );
};

const NewsletterPopUpStyled = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  transform: ${props =>
    props.show ? 'translateZ(0)' : 'translate3d(0, 100%, 0)'};
  transition: ${props => (props.show ? 'transform .8s' : 'transform 1s')};
  z-index: 3;
`;

const PopUpStyled = styled(Box)`
  position: absolute;
  width: 95%;
  max-width: 41.25rem;
  bottom: 50px;
  left: 50%;

  z-index: 199;

  display: grid;
  grid-template-columns: 240px 2fr;

  transform: translateX(-50%);
  background-color: white;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);
  border-radius: 24px;

  ${phone(css`
    bottom: 16px;
    grid-template-columns: 1fr;
  `)};
`;

const Close = styled(FontAwesomeIcon)`
  position: absolute;
  top: 12px;
  right: 16px;

  font-size: 24px;
  color: #c0c0c0;

  cursor: pointer;

  ${phone(css`
    top: 8px;
    right: 8px;
  `)};
`;

const Image = styled(GatsbyImage)`
  width: 246px;
  height: 342px;
  border-radius: 24px 0 0 24px;

  & > picture {
    & > img {
      border-radius: 24px 0 0 24px;
    }
  }

  ${phone(css`
    display: none;
  `)};
`;

const Title = styled(H5)`
  margin-bottom: 24px;

  ${phone(css`
    display: none;
  `)};
`;

const TitleMobile = styled(H5)`
  display: none;

  ${phone(css`
    display: block;
    margin-bottom: 16px;
  `)};
`;

const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: ${props => props.theme.fonts.family};
`;

export default NewsletterPopUp;

import React from 'react';
import sys from 'system-components';
import theme from '../layouts/theme';
import { Text } from 'rebass';

export const H1Style = sys({
  is: Text,
  fontFamily: theme.fonts.family,
  fontWeight: 600,
  fontSize: [6, 6, 7, 7, 8, 8],
  my: [4, 5],
  color: theme.colors.blackish,
});

export const H1 = ({ children, ...props }) => {
  return <H1Style {...props}>{children}</H1Style>;
};

export const H2Style = sys({
  is: Text,
  fontFamily: theme.fonts.family,
  fontWeight: 600,
  fontSize: [3, 3, 5, 5, 6, 7],
  my: [2, 3],
  color: theme.colors.blackish,
});

export const H2 = ({ children, ...props }) => {
  return <H2Style {...props}>{children}</H2Style>;
};

export const H3Style = sys({
  is: Text,
  fontFamily: theme.fonts.family,
  fontSize: [5, 5, 5, 5, 6, 6],
  fontWeight: 600,
  lineHeight: '126%',
  my: [2, 3],
  color: theme.colors.blackish
});

export const H3 = ({ children, ...props }) => {
  return <H3Style {...props}>{children}</H3Style>;
};

export const H4Style = sys({
  is: Text,
  fontFamily: theme.fonts.family,
  fontSize: [4, 5],
  fontWeight: 600,
  my: [1, 2],
  color: theme.colors.blackish
});

export const H4 = ({ children, ...props }) => {
  return <H4Style {...props}>{children}</H4Style>;
};

export const H5Style = sys({
  is: Text,
  fontFamily: theme.fonts.family,
  fontSize: [2, 3, 4],
  fontWeight: 500,
  my: [1, 2],
  color: theme.colors.blackish
});

export const H5 = ({ children, ...props }) => {
  return <H5Style {...props}>{children}</H5Style>;
};

export const H6Style = sys({
  is: Text,
  fontFamily: theme.fonts.family,
  fontSize: [2, 3],
  fontWeight: 600,
  my: [1, 2],
  color: theme.colors.blackish
});

export const H6 = ({ children, ...props }) => {
  return <H6Style {...props}>{children}</H6Style>;
};

import React from 'react';
import sys from 'system-components';
import theme from '../layouts/theme';
import { Text } from 'rebass';

export const Body1Style = sys({
  is: Text,
  fontSize: [2, 2, 2, 2, 3, 3],
  fontFamily: theme.fonts.family,
  color: theme.colors.blackish,
  lineHeight: ['27px', '27px', '27px', '27x', '35px', '35px']
});

export const TextBody1 = ({ children, ...props }) => {
  return <Body1Style {...props}>{children}</Body1Style>;
};

export const Body2Style = sys({
  is: Text,
  fontSize: [1],
  fontFamily: theme.fonts.family,
  color: theme.colors.blackish70,
  lineHeight: ['22px', '25px']
});

export const TextBody2 = ({ children, ...props }) => {
  return <Body2Style {...props}>{children}</Body2Style>;
};

export const MiniTextStyle = sys({
  is: Text,
  fontSize: [1],
  fontFamily: theme.fonts.family,
  color: theme.colors.gray[1],
  lineHeight: ['22px', '22px', '22px', '22px', '26px', '26px']
});

export const MiniText = ({ children, ...props }) => {
  return <MiniTextStyle {...props}>{children}</MiniTextStyle>;
};

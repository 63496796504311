import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { dom } from "@fortawesome/fontawesome-svg-core";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faUsers,
  faCalendarAlt,
  faDumbbell,
  faMusic,
  faBars,
  faSpinner,
  faInfo,
  faTimes,
  faAngleRight,
  faAngleLeft,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";

import theme from "../layouts/theme";
import "../layouts/global.css";
import { logDevsMessage } from "../utils/logs";
import Nav from "./nav/Nav";
import NewsletterPopUp from "./NewsletterPopup";
import Subscribe from "./Subscribe";
import Footer from "./Footer";
import GymiousBanner from "./GymiousBanner";

library.add(
  fab,
  faInfo,
  faSpinner,
  faCalendarAlt,
  faUsers,
  faDumbbell,
  faMusic,
  faBars,
  faTimes,
  faAngleRight,
  faAngleLeft,
  faPlay
);
dom.watch();

const GlobalStyle = createGlobalStyle`
  * { 
    box-sizing: border-box; 
    &::selection { 
      background: #2D3135;
      color: white;
    }
  }
  body {
    margin: 0;
  }
`;

logDevsMessage(theme);

const title =
  "Gymlovers | Entrevistamos pessoas apaixonadas pela atividade física";
const description =
  "Somos uma comunidade que celebra os atletas do dia-a-dia. Fazemos entrevistas a pessoas em boa forma física e damos a conhecer o seu dia-a-dia, hábitos de treino e alimentares e pequenos segredos que os ajudaram a atingir uma excelente forma física.";
const url = "https://gymlovers.pt";
const image = "/img/share.png";

const renderNavbar = (location) => {
  if (!(location.pathname === "/musica/" || location.pathname === "/musica")) {
    return <Nav />;
  }
};

const TemplateWrapper = ({ location, interview, children }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Helmet>
          <html lang="pt" />
          <title>{title}</title>
          <meta name="description" content={description} />

          <meta
            name="google-site-verification"
            content="i1ZcAiD-Ug9_avbU_TF8m0DK5jHUZVWyOHQ3VWUlu0c"
          />

          <meta itemprop="name" content={title} />
          <meta itemprop="description" content={description} />
          <meta itemprop="image" content={`${url}${image}`} />

          <meta property="og:url" content={url} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={`${url}${image}`} />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={description} />
          <meta name="twitter:image" content={`${url}${image}`} />
        </Helmet>
        <GymiousBanner />
        {renderNavbar(location)}
        <GlobalStyle />
        <div>{children}</div>
        <Subscribe />
        <Footer />
        {interview && <NewsletterPopUp interview={interview} />}
      </>
    </ThemeProvider>
  );
};

TemplateWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  interview: PropTypes.object,
  location: PropTypes.object,
};

export default TemplateWrapper;

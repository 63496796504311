import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { Container } from '../../ui';
import { Link as RebassNavLink } from 'rebass';
import { phone } from '../../layouts/media';
import theme from '../../layouts/theme';
import Logo from './Logo';

const navLinks = [
  {
    to: '/entrevistas',
    text: 'Entrevistas'
  },
  {
    to: '/musica',
    text: 'Música'
  },
  {
    to: '/sobre',
    text: 'Sobre nós'
  }
];

const Navbar = ({ inverted }) => (
  <Nav inverted={inverted}>
    <Container width={1}>
      <NavContainer>
        <RebassNavLink as={Link} to="/">
          <Logo color={inverted && 'white'} />
        </RebassNavLink>
        <NavLinks>
          {navLinks.map(navLink => (
            <NavLink
              key={navLink.to}
              inverted={inverted}
              fontSize={[1, 2]}
              py={1}
              activeStyle={{
                borderBottom: `2px solid  ${
                  inverted ? theme.colors.beige : theme.colors.blackish
                }`
              }}
              as={Link}
              to={navLink.to}
            >
              {navLink.text}
            </NavLink>
          ))}
          <NavLink
            id="subscribeLink"
            href="#subscribe"
            inverted={inverted}
            fontSize={[1, 2]}
            py={1}
          >
            Newsletter
          </NavLink>
        </NavLinks>
      </NavContainer>
    </Container>
  </Nav>
);

export default Navbar;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  height: 88px;
  background-color: ${props =>
    props.inverted ? 'transparent' : props.theme.colors.white};

  ${phone(css`
    display: none;
  `)};
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavLinks = styled.ul`
  margin: 0;
  text-align: right;
`;

const NavLink = styled(RebassNavLink)`
  margin-right: 26px;
  font-family: ${props => props.theme.fonts.family};
  font-weight: 500;
  text-decoration: none;
  color: ${props =>
    props.inverted ? props.theme.colors.beige : props.theme.colors.blackish};

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    border-bottom: 2px solid
      ${props =>
        props.inverted ? props.theme.colors.beige : props.theme.colors.blue};
  }
`;

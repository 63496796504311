export const getPostsForScreenSize = posts => {
  if (typeof window !== 'undefined' && window.innerWidth <= 1024) {
    return posts.slice(0, 2);
  } else {
    return posts.slice(0, 3);
  }
};

export const getFormatedDate = date => {
  const interviewDate = new Date(date);
  const day = new Intl.DateTimeFormat('pt', {
    day: 'numeric'
  }).format(interviewDate);
  const year = new Intl.DateTimeFormat('pt', {
    year: 'numeric'
  }).format(interviewDate);
  const portugueseMonth = new Intl.DateTimeFormat('pt', {
    month: 'long'
  }).format(interviewDate);
  return `${day} de ${capitalizeFirstLetter(portugueseMonth)}, ${year}`;
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const isMobile = () => {
  return typeof window !== 'undefined' && window.innerWidth <= 624;
}